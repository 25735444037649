@import url("https://fonts.googleapis.com/css2?family=Satoshi:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-satoshi: Satoshi;
  --font-inter: Inter;
  --font-roboto: Roboto;
  --font-poppins: Poppins;
  --font-inherit: inherit;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-21xl: 40px;
  --font-size-29xl: 48px;
  --font-size-5xl: 24px;
  --font-size-9xs-2: 3.2px;
  --font-size-6xs-4: 6.4px;
  --font-size-8xs-2: 4.2px;
  --font-size-10xs: 3px;
  --font-size-9xs-7: 3.7px;
  --font-size-9xs-5: 3.5px;
  --font-size-3xs-3: 9.3px;
  --font-size-2xs-6: 10.6px;

  /* Colors */
  --color-white: #fff;
  --color-midnightblue-100: #121139;
  --color-midnightblue-200: rgba(18, 17, 57, 0.1);
  --color-darkslategray-100: #3c3c3c;
  --theme-color-black: #313131;
  --color-darkslategray-200: rgba(60, 60, 60, 0.6);
  --color-darkslategray-300: rgba(60, 60, 60, 0.2);
  --color-whitesmoke-100: #f6fafb;
  --gray-100: #f7f7f7;
  --color-gray-100: #fcfcfc;
  --tkh-100: #212633;
  --color-gray-200: rgba(0, 0, 0, 0.15);
  --color-crimson: #dc2626;
  --color-lightslategray: #797f9d;
  --color-gainsboro: #e7e7e7;
  --color-dimgray-100: #5b5b5b;
  --color-dimgray-200: rgba(91, 91, 91, 0.7);
  --ink-80: #59637e;
  --ink-light-40: #9fa7c4;

  /* Gaps */
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-11xl: 30px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-10xl: 29px;
  --gap-41xl: 60px;
  --gap-5xs-2: 7.2px;
  --gap-lg-5: 18.5px;
  --gap-12xs-3: 0.3px;
  --gap-10xs-5: 2.5px;
  --gap-12xs: 1px;
  --gap-6xs-4: 6.4px;
  --gap-10xs-7: 2.7px;
  --gap-4xs-5: 8.5px;
  --gap-11xs-6: 1.6px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-11xl: 30px;
  --padding-5xl: 24px;
  --padding-21xl: 40px;
  --padding-9xl: 28px;
  --padding-xl: 20px;
  --padding-10xs-6: 2.6px;
  --padding-9xs: 4px;
  --padding-10xs: 3px;
  --padding-10xs-1: 2.1px;
  --padding-8xs-8: 4.8px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-81xl: 100px;
  --br-8xs-2: 4.2px;
  --br-10xs-1: 2.1px;
  --br-2xs-2: 10.2px;
  --br-10xs-4: 2.4px;
  --br-xs: 12px;
}
